:root {
    --col-1: 2.5rem;
    --col-2: 4.16666rem;
    --col-3: 2.777777rem;
    @include md() {
        --col-1: 75px;
    }
    @include lg() {
        --col-2: 75px;
        --col-3: 50px;
    }
    @include xxl() {
        --col-2: 175px;
        --col-3: 100px
    }
}
