@keyframes errorAnimation {
    from {
        //max-height: 0;
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        //max-height: 2rem;
        transform: translateY(0);
        opacity: 1;
    }
}

.form {
    position: relative;
    overflow: hidden;

    &__field {
        margin-top: 20px;
        margin-bottom: 20px;
        @include md() {
            margin-top: 40px;
            margin-bottom: 40px;
        }
        @include xxl() {
            margin-top: 1.708333rem;
            margin-bottom: 2.375rem;
        }
    }

    &__control {

        background-color: var(--bg, #fff);
        border: 0;
        color: var(--control-color, rgba(34, 34, 34, 0.2));
        border-bottom: 1px solid rgba(34, 34, 34, 0.2);
        border-left: 1px solid var(--control-color, rgba(34, 34, 34, 0.2));
        border-bottom: 1px solid var(--control-color, rgba(34, 34, 34, 0.2));
        display: block;
        appearance: none;
        width: 100%;
        padding: 15px 20px;
        transition: 0.25s;
        border-radius: 0;
        @include lg() {
            padding: 15px 30px 20px;
            font-size: 24px;
        }
        @include xxl() {
            padding: 0.625rem 1.25rem 0.8333rem;
            font-size: 1rem;
        }
    }

    &__control::placeholder {
        color: #222;
    }

    &__control:hover {
        --control-color: rgba(34, 34, 34, 0.6);
    }


    &__control:focus,
    &__control:not(:placeholder-shown) {
        --control-color: #222;
        outline: none;
    }

    &__control:focus-visible:focus {
        outline: inherit;
    }

    &__control:invalid:not(:placeholder-shown) {
        --control-color: #FF123C;
    }


    &__control--error {
        --control-color: #FF123C !important;
    }
    &__control--error::placeholder {
        color: #FF123C;
    }
    &__error-message {
        margin-top: 0.5555rem;
        font-size: m#{a}x(14px, 0.5555rem);
        color: #FF123C;
        @include xxl() {
            font-size: 0.5833rem;
            position: absolute;
        }
        //animation: 0.5s errorAnimation linear;
    }

    &__btn {
        margin-top: 2.2222rem;
        margin-bottom: 2.2222rem;
        border: none;
        background: none;
        height: 6.6666rem;
        position: relative;
        display: flex;
        align-items: center;
        text-align: left;
        cursor: pointer;
        padding: 0;
        @include xxl() {
            margin-bottom: 1.6666rem;
            flex: 0 0 50%;
            margin-top: 0;
            height: 5rem;
        }
    }

    &__btn::before {
        content: '';
        width: 6.66666rem;
        height: 6.66666rem;
        background-color: #fff;
        border-radius: 3.33333rem;
        transition-duration: 0.25s;
        flex: 0 0 auto;
        @include xxl() {
            width: 5rem;
            height: 5rem;
            border-radius: 2.5rem;
        }
    }

    //&__btn::after {
    //    content: '';
    //    width: 3.3333rem;
    //    height: 1.888rem;
    //    display: block;
    //    background-size: 200% 100%;
    //    background-position: 100% center;
    //    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='125' height='35' fill='none' viewBox='0 0 125 35'%3E%3Cpath stroke='%23fff' d='M0 17.65h60.44m0 0L43.8 34.3m16.65-16.65L43.8 1'/%3E%3Cpath stroke='%23222' d='M63 17.65h60.44m0 0L106.8 34.3m16.65-16.65L106.8 1'/%3E%3C/svg%3E");
    //    position: absolute;
    //    top: 3.3333rem;
    //    left: 3.61111rem;
    //    transform: translate(-50%, -50%);
    //    transition: 0.25s;
    //    //animation: 0.25s btnHoverIn linear;
    //    @include xxl() {
    //        width: 2.5rem;
    //        height: 1.416rem;
    //        top: 2.5rem;
    //        left: 2.70833rem;
    //    }
    //}
    &__btn .icon {
        width: 3.3333rem;
        height: 1.888rem;
        display: block;
        position: absolute;
        top: 3.3333rem;
        left: 3.61111rem;
        transform: translate(-50%, -50%);
        transition: 0.25s;
        @include xxl() {
            width: 2.5rem;
            height: 1.416rem;
            top: 2.5rem;
            left: 2.70833rem;
        }
    }


    &__btn:focus::before,
    &__btn:hover::before {
        background-color: map-get($palette, 'secondary');
    }

    &__btn:focus .icon,
    &__btn:hover .icon{
        fill: #fff;
    }

    &__btn:focus::after,
    &__btn:hover::after {
        //background-position: 0% center;
        //animation: 0.25s btnHoverOut linear;
    }

    &__btn:active::before{
        transform: scale(0.7);
    }
    &__btn:active::after{
        transform: translate(-50%, -50%) scale(0.7);
    }

    &__btn span {
        display: block;
        margin-left: 0.83333em;
        font-size: 1.3333rem;
        @include lg() {
            font-size: 1rem;
        }
    }

    &__footer {
        @include xxl() {
            display: flex;
flex-wrap: wrap;
        }
    }

    &__politics {
        color: #C4C4C4;
        font-size: 0.7777rem;
        line-height: 1.42;
        @include md() {
            font-size: 1rem;
        }

        @include xxl() {
            flex: 0 0 100%;

            font-size: 0.75rem;
            margin-top: 0.5rem;
        }

        @include r(1500){
            flex:  0 0 50%;
            padding-left: var(--col-3);
            padding-right: var(--col-3) + var(--col-1);
        }
    }

    &__politics:hover {
        opacity: 1;
    }
    &__politics a:hover{
        color: #222;
    }

    &__form {
        margin-top: 2.22222rem;
        @include xxl() {
            margin-top: 0;
        }
    }

    &__label {
        position: absolute;
        top: 35%;
        right: -5%;
        pointer-events: none;
        transition: 0.25s;
        @include md() {
            right: 50px;
        }
        @include lg() {
            transform: rotate(-10deg);
        }
        @include xxl() {
            right: 2.0833rem;
        }
    }

    &__label-wrapper {
        box-sizing: content-box;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='216' height='116' viewBox='0 0 216 116' preserveAspectRatio='none'%3E%3Cpath fill='%2317423d' d='M191 0H20.8A25.04 25.04 0 010 21.7V91a25 25 0 0125 25h166a25 25 0 0125-25V25a25 25 0 01-25-25z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: block;
        padding: 1.38888rem 1.6666rem;
        width: 12rem;
        color: #fff;
        font-size: 0.7rem;
        line-height: 1.285;
        transition: 0.1s;
        width: 8.6666rem;
        @include lg() {
            width: 13.88888rem;
            padding: 1.58333rem 1.458333rem;
            background-image: url("data:image/svg+xml,%3Csvg width='322' height='144' viewBox='0 0 322 144' fill='none' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M301.178 0L20.8218 0C19.4975 11.0737 10.9345 19.9233 0 21.6817L0 123.318C10.6033 125.023 18.9766 133.397 20.6817 144L297 144C297 130.193 308.193 119 322 119V21.6817C311.066 19.9233 302.502 11.0737 301.178 0Z' fill='%2317423D'/%3E%3C/svg%3E%0A");
        }

    }

    &__label-price {
        font-size: 1.111rem;
        line-height: 1.5;
        color: map-get($palette, 'secondary');
        white-space: nowrap;
        font-family: 'NeutralFace', sans-serif;
        @include lg() {
            font-size: 1.77777rem;
        }
    }

    &:focus-within &__label {
        @include rmax(699) {
            transform: translate(100vw) !important;
            transition: 0.25s;
        }
    }

    &__wrapper {
        @include lg() {
            display: flex;
        }
    }

    &__action {
        @include lg() {
            flex: 0 0 calc(var(--col-1) + var(--col-2));
            line-height: 1.5;
            font-size: 0.75rem;
            margin-top: 0.5rem;
        }
    }

    &__wrap {
        @include lg() {
            padding-right: calc(var(--col-1) + var(--col-2));
        }
    }

    &__title {
        @include lg() {
            margin-top: -5px;
        }
        @include xxl() {
            margin-top: 0.208rem;
        }
    }
    &__title span{
        display: block;
        max-width: 10ch;
    }
}

@keyframes btnHoverIn {
    from {
        background-position: 200% center;
    }
    to {
        background-position: 100% center;
    }
}

@keyframes btnHoverOut {
    from {
        background-position: 100% center;
    }
    to {
        background-position: 0% center;
    }
}


.form-response {
    position: relative;

    &__response {
        position: absolute;
        background-color: #EEEEEE;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transform: translate(-50%);
        transition: 0.25s;
        pointer-events: none;
    }

    &__response .form__wrap {
        max-width: 29rem;
    }

    &__text {
        margin-top: 1.6666rem;
    }

    &--sended &__response {
        transform: translate(0);
        opacity: 1;
        pointer-events: auto;

    }
    &__btn{
        margin-top: 2.222rem;
        @include xxl() {
            margin-top: 1.6666rem;
        }
    }

}
