.footer {
    position: relative;
    width: 100%;
    background-color: map-get($palette, 'primary');
    overflow: hidden;

    &__wrapper {
        @include lg{
            display: flex;
            width: 100%;
        }
        @include xxl() {
            margin-top: 1.7rem;
        }
    }

    &__header {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        @include lg() {
            flex: 0 0 auto;
            max-width: 50%;
            width: 100%;
            justify-content: flex-start;
            flex-direction: column;
        }
        @include xxl() {
            flex-direction: row;
        }
    }

    &__action {
        @include rmax(355){
            width: 100%;
            margin-top: 2.2rem;
        }
        @include md(){
            width: 50%;
        }
        @include lg() {
            margin-top: 40px;
        }
        @include xxl() {
            margin-top: 2px;
            padding-left: 1.4583333rem;
        }
    }

    &__contacts {
        display: flex;
        flex-wrap: wrap;

        @include lg() {
            flex: 0 0 auto;
            max-width: 50%;
            width: 100%;
        }
        @include xxl() {
            justify-content: space-between;
        }
    }

    &__contacts-wrapper {
        flex: 0 0 auto;
        width: 100%;
        max-width: 100%;

        margin-top: 2.2222rem;
        @include md() {
            max-width: 50%;
            margin-top: 4.4444rem;

        }
        @include lg() {

            margin-top: 0;
            max-width: 100%;
        }
        @include xxl() {

            margin-top: 3px;
            width: 50%;
            max-width: 12.5rem;
            flex-grow: 1;
        }
    }

    &__contacts-wrapper + &__contacts-wrapper {
        @include lg() {
            margin-top: 60px;
        }
        @include xl(){
            margin-top: 3rem;
        }
        @include xxl() {
            margin-top: 3px;
            flex-grow: 0;
            max-width: 12.5rem;
        }
    }

    &__contact-title {
        color: map-get($palette, 'secondary');
        font-size: 1.333rem;
        line-height: 1.5;
        @include xxl() {
            font-size: 1rem;
        }
    }

    &__contact-desc {
        line-height: 1.44444;
        margin-top: 0.5555rem;
        @include lg() {
            margin-top: 1.1111rem;
        }
        @include xxl() {
            font-size: 0.75rem;
            margin-top: 0.9rem;
        }
    }

    &__contact-link {
        color: #fff;
        border-bottom: none;
    }

    &__copyright {
        border-top: 1px solid rgba(#fff, 0.2);
        margin-top: 2.2222rem;
        padding-top: 1.666rem;
        @include lg() {
            margin-top: 60px;
            padding-top: 40px;
        }
        @include xxl() {
            margin-top: 5rem;
            padding-top: 1.2rem;
        }
    }
}

.footer-copy {

    @include xxl(){font-size: 0.75rem;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
    }

    @include md() {
        justify-content: space-between;
    }

    &__right,
    &__design,
    &__description {
        margin-top: 0.5555rem;
        flex: 0 0 auto;
        width: 100%;
        @include md() {
            max-width: 50%;
        }
    }

    &__description {
        font-size: 0.777777rem;
        line-height: 1.428;
        color: #748E8B;
        @include md() {
            font-size: 1rem;
            order: 2;
            max-width: 100%;
        }
        @include xxl(){
            font-size: 0.75rem;
        }
    }
    &__description a{
        color: inherit;
    }
    &__description a:hover{
        color: #fff;
    }

    &__right,
    &__design {
        @include md() {
            max-width: 50%;
        }
    }

    &__right {
        @include xxl() {
            max-width: calc(100% - 12.5rem);
        }
    }

    &__design {
        @include xxl() {
            max-width: 12.5rem;
        }
    }

    &__description {
        @include xxl() {
            margin-top: 0;
        }
    }
}

