$opacity: 0.3;
$opacity-dashed: 0.5;

@mixin link($color, $color-hover: $color, $color-focus: $color-hover, $border-color: $color,
$border-color-hover: $color-hover, $border-color-focus: $color-focus) {

    $opacity-hover: if($color-hover == $color, 1, $opacity);
    $opacity-dashed-hover: if($color-hover == $color, 1, $opacity-dashed);

    color: $color;
    //border-bottom-color: rgba($border-color, $opacity);

    //u {
    //    border-bottom-color: rgba($border-color, $opacity);
    //}

    &:hover {
        color: $color-hover;
        //border-bottom-color: rgba($border-color-hover, $opacity-hover);
    }

    //&:hover u {
    //    border-bottom-color: rgba($border-color-hover, $opacity-hover);
    //}

    //&.dashed,
    //&.dashed u {
    //    border-bottom-color: rgba($border-color, $opacity-dashed);
    //}

    //&.dashed:hover,
    //&.dashed:hover u {
    //    border-bottom-color: rgba($border-color-hover, $opacity-dashed-hover);
    //}
}

a,
a u,
.link-button u {
    text-decoration: none;
    border-color: inherit;
    //border-bottom-width: 1px;
    //border-bottom-style: solid;
    transition-duration: 0.3s;
}

a {
    @include link(map-get($palette,'secondary'), map-get($palette,'secondary'));
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    outline-color: inherit;
    transition-property: color, border-color;

    &:active,
    &:hover {
        outline-width: 0;
    }
}

a u,
.link-button u {
    transition-property: border-color;
}

a[href^="tel"] {
    text-decoration: none;
    color: inherit;
    border: none;
}

a:hover,
a:hover u {
    //transition-duration: 0.1s;
}

.dashed,
.dashed u {
    border-bottom-style: dashed;
}

.no-border {
    border-bottom: none;
}

.multi-line {
    padding: 0.6em 0 0;
}

.link-button {
    display: inline;
    border: none;
    background-color: transparent;
    font-size: 1em;
    text-align: inherit;
    font-family: inherit;
    padding: 0;
    transition-duration: 0.3s;
    @include link(map-get($palette,'secondary'), map-get($palette,'secondary'));

    &:active,
    &:hover,
    &:focus {
        cursor: pointer;
        outline-width: 0;
    }
}

.link{
    color: inherit;
    border-bottom-color: currentColor;
}

