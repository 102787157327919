// width
@mixin r($width) {
    @media only screen and (min-width: $width + "px") {
        @content;
    }
}

@mixin rmax($width) {
    @media only screen and (max-width: $width + "px") {
        @content;
    }
}

// height
@mixin rh($height) {
    @media only screen and (max-height: $height + "px") {
        @content;
    }
}

@mixin rhmin($height) {
    @media only screen and (min-height: $height + "px") {
        @content;
    }
}

@mixin css-lock($min-size: 1, $max-size: 1.4, $min-width: 20, $max-width: 100, $property: font-size, $unit: px) {
    #{$property}: #{$max-size}#{$unit};
    #{$property}: calc(#{$min-size}#{$unit} + (#{$max-size} - #{$min-size}) * ((100vw - #{$min-width}#{$unit}) / (#{$max-width} - #{$min-width})));

    @media (max-width: #{$min-width}#{$unit}) {
        #{$property}: #{$min-size}#{$unit};
    }

    @media (min-width: #{$max-width}#{$unit}) {
        #{$property}: #{$max-size}#{$unit};
    }
}

@mixin fs($sizeValue: 16) {
    font-size: $sizeValue + px;
    font-size: $sizeValue / 10 + rem;
}

@mixin text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin hover {
    .no-touch &:hover {
        @content;
    }
}

%pseudo {
    position: relative;

    &:before, &:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
    }
}

@mixin pseudo {
    @extend %pseudo;
}

@mixin coverdiv {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin cover {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

@mixin bg-size($width: 100%, $height: 100%) {
    background-size: $width $height;
}

// retina, also available as +hdpi
@mixin retina {
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
        @content;
    }
}

// vertical-align at center
@mixin vcenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

//simple gradient
@mixin g($color1, $color2) {
    background-image: linear-gradient($color1, $color2);
}

@mixin gh($color1, $color2) {
    background-image: linear-gradient(left, $color1, $color2);
}

// font-family
@mixin font($alias, $name) {
    @font-face {
        font-family: $alias;
        src: url("../fonts/" + $name + ".woff2") format("woff2"), url("../fonts/" + $name + ".woff") format("woff");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
}

// to use:
//+font(black, cytiapro-black-webfont)

// ROTATE
@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
    transform: rotate($deg + deg);
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=$m11, M12=$m12, M21=$m21, M22=$m22, sizingMethod='auto expand');
}

// TRANSITION

@mixin tr($what) {
    transition: $what;
}

@mixin hide-text {
    font: 0 / 0 a;
    text-shadow: none;
    color: transparent;
}

@mixin hidetext {
    @include hide-text;
}

// IMPORTANT!!!! REQUIRES SPACES BETWEEN ELEMENTS, NO SUCH STUFF: </li><li>
@mixin justify {
    text-align: justify;
    line-height: 0;
    font-size: 0;
    text-justify: newspaper;
    text-align-last: justify;

    &:after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 0px;
        visibility: hidden;
        overflow: hidden;
    }
}

@mixin vertical {
    text-align: center;
    font-size: 0;

    &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%;
    }
}

@mixin clr {
    &:after {
        content: " ";
        display: table;
        clear: both;
    }
}

@mixin placeholder-color($color) {
    &::placeholder {
        color: $color;
    }
}

@mixin placeholder {
    &::placeholder {
        @content;
    }
}

@mixin gray {
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");

    // Firefox 10+
    filter: gray;

    // IE6-9
    -webkit-filter: grayscale(100%);

    // Chrome 19+ & Safari 6+
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=1);
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(grayScale=1)";
}

@mixin gray-half {
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='saturate' values='0.5'/></filter></svg>#grayscale");

    // Firefox 10+
    filter: gray alpha(opacity=50);

    // IE6-9
    -webkit-filter: grayscale(50%);

    // Chrome 19+ & Safari 6+
}

@mixin gray-zero {
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(0%);
}

@mixin unselectable {
    user-select: none;
}

@mixin nosel {
    @include unselectable;
}

@mixin prevent-text {
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
}

@mixin font-smoothing($val: antialiased) {
    font-smoothing: $val;
}

@mixin selection {
    & ::selection {
        @content;
    }
}

@mixin sel {
    @include selection;
}

@mixin gpu {
    transform: translate3d(0, 0, 0);
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        @content;
    }
}

@mixin scrollbar-track {
    &::-webkit-scrollbar-track {
        @content;
    }
}

@mixin scrollbar-thumb {
    &::-webkit-scrollbar-thumb {
        @content;
    }
}

@mixin scrollbar-track-piece {
    &::-webkit-scrollbar-track-piece {
        @content;
    }
}

//triangle
@mixin arr($width, $height, $bg, $direction) {
    width: 0px;
    height: 0px;
    border-style: solid;

    @if $direction == t {
        border-width: 0 ($width / 2 + px) ($height + px) ($width / 2 + px);
        border-color: transparent transparent $bg transparent;
    }

    @if $direction == r {
        border-width: ($height / 2 + px) 0 ($height / 2 + px) ($width + px);
        border-color: transparent transparent transparent $bg;
    }


    @if $direction == b {
        border-width: ($height + px) ($width / 2 + px) 0 ($width / 2 + px);
        border-color: $bg transparent transparent transparent;
    }

    @if $direction == l {
        border-width: ($height / 2 + px) ($width + px) ($height / 2 + px) 0;
        border-color: transparent $bg transparent transparent;
    }

    @if $direction == tl {
        border-width: ($height + px) ($width + px) 0 0;
        border-color: $bg transparent transparent transparent;
    }

    @if $direction == tr {
        border-width: 0 ($width + px) ($height + px) 0;
        border-color: transparent $bg transparent transparent;
    }

    @if $direction == br {
        border-width: 0 0 ($height + px) ($width + px);
        border-color: transparent transparent $bg transparent;
    }

    @if $direction == bl {
        border-width: ($width + px) 0 0 ($height + px);
        border-color: transparent transparent transparent $bg;
    }
}

// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

@mixin rotate($var) {
    transform: rotate($var + deg);
}

@mixin blur($var) {
    filter: blur($var);
}

@mixin better-blur($radius) {
    filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a' x='0' y='0' width='1' height='1' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur stdDeviation='#{$radius}' result='b'/%3E%3CfeMorphology operator='dilate' radius='#{$radius}'/%3E %3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='b'/%3E%3C/feMerge%3E%3C/filter%3E %3C/svg%3E#a");
}

@mixin scale($var) {
    transform: scale($var);
}

@mixin counter($var, $sep) {
    counter-reset: list + $var;

    > li {
        &:before {
            content: counter(list + $var) $sep;
            counter-increment: list + $var;
        }
    }
}

@mixin f($name, $font-size: null, $letter-spacing: null) {
    $font-family: null;

    @if $name == "l" {
        $font-family: "light";
    }

    font-family: $font-family;
    font-weight: normal;

    @if $font-size != null {
        font-size: $font-size + px;
    }

    @if $letter-spacing != null {
        letter-spacing: $letter-spacing / 1000 + em;
    }
}

@mixin font-face($font-family, $alias, $file-path, $weight: normal, $style: normal, $asset-pipeline: false) {
    @font-face {
        font-family: $font-family;
        font-weight: $weight;
        font-style: $style;
        src: url("#{$file-path}.eot");
        src: local("#{$font-family} #{$alias}"), local("#{$font-family}-#{$alias}"),
        url("#{$file-path}.eot?#iefix") format('embedded-opentype'),
        url("#{$file-path}.woff") format('woff2'),
        url("#{$file-path}.woff") format('woff'),
        url("#{$file-path}.ttf") format('truetype') {
        }
    }
}

@mixin center($axis) {
    @if $axis == both {
        @include vcenter;
    } @else if $axis == h {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    } @else if $axis == v {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

@mixin button-active-classes($hover: false) {
    @if $hover {
        &:hover,
        &:active,
        &:focus,
        &.active {
            @content;
        }
    } @else {
        &:active,
        &:focus,
        &.active {
            @content;
        }
    }
}

@mixin button-disabled-classes() {
    &:disabled,
    &[disabled],
    fieldset[disabled] &,
    &.disabled {
        @content;
    }
}

@mixin button($font-family: inherit, $font-weight: 400) {
    appearance: none;
    max-width: 100%;
    display: inline-block;
    text-decoration: none;
    border: none;
    vertical-align: middle;
    cursor: pointer;
    transition-property: border-color, background-color, color, box-shadow, background;
    transition-duration: 0.25s;

    font-family: $font-family;
    font-weight: $font-weight;
    font-size: inherit;

    &:hover {
        transition-duration: 0.25s;
    }
    &:focus {
        outline: none;
    }

    @include button-disabled-classes() {
        pointer-events: none;
    }

    &--block {
        display: block;
        width: 100%;
        padding: 0;
        text-align: center;
    }
}

@mixin button-size($height, $font-size: 1rem, $border-width: 0, $border-radius: 0, $padding-horizontal: ($height * $btn-horizontal-padding-multiplier)) {
    @if $border-width != 0 {
        padding: calc((#{$height} - #{$font-size} * #{$form-control-line-height} - #{2 * $border-width}) / 2) $padding-horizontal*$form-control-line-height;
    } @else {
        padding: calc((#{$height} - #{$font-size} * #{$form-control-line-height}) / 2) $padding-horizontal*$form-control-line-height;
    }

    border-width: $border-width;
    border-radius: $border-radius;
    font-size: $font-size;
    line-height: $form-control-line-height;
}

@mixin button-color($color, $text-color,
$color-hover: lighten($color, 5), $text-color-hover: $text-color,
$color-active: darken($color, 10), $text-color-active: $text-color,
$color-disabled: lighten($color, 35), $text-color-disabled: lighten($text-color, 35)) {
    background-color: $color;
    color: $text-color;

    &:hover {
        background-color: $color-hover;
        color: $text-color-hover;
    }

    @include button-active-classes() {
        background-color: $color-active;
        color: $text-color-active;
    }

    @include button-disabled-classes() {
        background-color: $color-disabled;
        color: $text-color-disabled;
    }
}

@mixin button-border-color($color, $color-hover: darken($color, 10),
$color-active: darken($color, 20), $color-disabled: lighten($color, 35)) {
    border-color: $color;

    &:hover {
        border-color: $color-hover;
    }

    @include button-active-classes() {
        border-color: $color-active;
    }

    @include button-disabled-classes() {
        border-color: $color-disabled;
    }
}
