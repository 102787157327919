$form-control-sizes: (
    default: (height: 2.2222rem, font-size: 0.777rem, field-radius: 0.3rem, button-radius: 5rem),
);
$btn-horizontal-padding-multiplier: 0.375;
$form-control-line-height: 1.25;
$btn-colors: (
    primary: (text: white),
);

.btn {
    white-space: nowrap;
    @include button();
}


.btn {
    @each $size in map_keys($form-control-sizes) {
        @if $size == default {
            @include button-size(map-deep-get($form-control-sizes, $size, "height"), map-deep-get($form-control-sizes, $size, "font-size"), 1px, map-deep-get($form-control-sizes, $size, "button-radius"));
            @include md() {
                @include button-size(50px, 18px, 1px, map-deep-get($form-control-sizes, $size, "button-radius"));
            }
            @include xxl() {
                @include button-size(2.08333rem, 0.75rem, 0.041rem, 4.1666rem);
            }
        } @else if ($size == "tiny") {
            &--#{$size} {
                @include button-size(
                        map-deep-get($form-control-sizes, $size, "height"),
                        map-deep-get($form-control-sizes, $size, "font-size"),
                        0,
                        map-deep-get($form-control-sizes, $size, "button-radius"),
                        map-deep-get($form-control-sizes, $size, "height")/2
                );
            }
        } @else {
            &--#{$size} {
                @include button-size(map-deep-get($form-control-sizes, $size, "height"), map-deep-get($form-control-sizes, $size, "font-size"), 0, map-deep-get($form-control-sizes, $size, "button-radius"));
            }
        }
    }

    color: map-get($palette, 'secondary');
    background-color: transparent;
    border: 1px solid map-get($palette, 'secondary');
    transition: 0.25s;

    &:hover,
    &:focus {
        color: map-get($palette, 'primary');
        background-color: map-get($palette, 'secondary');

    }

    &:active {
        transform: scale(0.8);

    }

    @include lg() {
        padding-left: 30px;
        padding-right: 30px;
    }
    @include xxl() {
        $padding: 2.0833333rem *   $btn-horizontal-padding-multiplier*$form-control-line-height;
        padding-left: $padding;
        padding-right: $padding;
    }
}
