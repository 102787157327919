.logo{
    position: relative;
    height: 2.2222rem;
    @include md(){
        left: 0;
        height: 54px;
        margin-top: -0.1rem;
    }
    @include xxl(){
        height: 2.25rem;
    }

    svg{
        height: 100%;
        width: auto;
    }
    &__d{
        display: none;
        @include md(){
            display: block;
        }
    }
    &__m{
        @include md(){
            display: none;
        }
    }
}
