html{
    scroll-behavior: smooth;
}
body{
    -webkit-text-size-adjust: none;
}
.wrapper {
    --container-min: calc(100% - var(--container-padding,15px));
    --spacer-vert:3.3333rem;
    --spacer-hor: 0.83333rem;

    padding-top: var(--spacer-vert,60px);
    padding-bottom: var(--spacer-vert,60px);
    padding-left: m#{a}x(var(--spacer-hor,15px),env(safe-area-inset-left));
    padding-right: m#{a}x(var(--spacer-hor,15px),env(safe-area-inset-right));

    @include md(){
        --spacer-hor: 50px;
        --spacer-vert: 80px;
    }

    @include lg(){
        --spacer-vert: 120px
    }

    @include xxl() {
        --spacer-hor: 140px;
        //--spacer-hor:calc((100% - var(--container-width))  / 2);
    }

    @include rh(500){
        --spacer-vert: 15vh;
    }
    @each $name, $color in $palette {
        &--#{$name} {
            --bg: #{$color};
            background-color: $color;
            color: contrast-color($color,#222222);
        }
    }
    &--header{
        padding-top: 0;
        padding-bottom: 0;
    }
    &--hero{
        padding-top: 40px;
        padding-bottom: 80px;


        @include md(){
            padding-top: 60px;
            padding-bottom: 120px;
        }
        @include lg(){
            padding-bottom: 180px;

        }
        @include xxl(){
            padding-bottom: 240px;
        }
        @include rh(500){
            padding-top: 20px;
        }
    }
    &--form{
        @include lg(){
            padding-top: 180px;
        }
        @include xxl(){
            padding-top: 360px;
            padding-bottom: 200px;
        }
    }
    &--what{
        @include lg(){
            padding-top: 80px;
        }
        @include xxl(){
            padding-top: 110px;
            padding-bottom: 175px;
        }
    }
    &--footer{
        padding-top: 40px;
        padding-bottom: 40px;
        @include xxl(){
            padding-bottom: 4.16666rem;
        }
    }
}

