.header{
    position: relative;
    width: 100%;
    background-color: map-get($palette,'primary');
    opacity: 1!important;
    visibility: visible!important;

    &__wrapper{
        display: flex;
        padding-top: 20px;
        width: 100%;
        justify-content: space-between;
        @include md(){
            padding-top: 40px;
        }
        @include lg(){
            padding-top: 60px;
        }
        @include xxl(){
            justify-content: flex-start;
        }
        @include rh(320){
            padding-top: 10px;
        }
        @include rh(500){
            padding-top: 20px;
        }
    }
    &__logo{

        @include xxl(){
            margin-right: 1.458888rem;
        }
    }
}

