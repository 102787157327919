@function em($size, $context: 16) {
    @return $size / $context * 1em;
}

@function img-url($image) {
    @return url("../img/#{$image}");
}

@mixin image($image) {
    background-image: img-url($image);
}

@mixin trans($what: all, $dur: 0.2s, $easing: ease) {
    transition: $what $dur $easing;
}

@mixin smooth-scroll {
    -webkit-overflow-scrolling: touch;
}

@mixin smooth-font {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin gpu {
    transform: translate3d(0, 0, 0);
}

@mixin xxl {
    @include r(1400) {
        @content;
    }
}

@mixin xl {
    @include r(1200) {
        @content;
    }
}

@mixin lg {
    @include r(1024) {
        @content;
    }
}

@mixin md {
    @include r(700) {
        @content;
    }
}

@mixin sm {
    @include r(575) {
        @content;
    }
}


@function map-deep-get($map, $keys...) {
    $value: $map;

    @each $key in $keys {
        $value: map-get($value, $key);
    }

    @return $value;
}

.debug {
    * {
        outline: 1px solid red;
    }

    *::before {
        outline: 2px dashed blue;

    }

    *::before {
        outline: 2px dashed green;
    }
}
