.soc{
    display: flex;
    margin-top: 1.1111rem;
    @include xxl(){
        margin-top: 0.8rem;
    }
    &__item{
        margin-right: 0.83rem;
        border: 1px solid map-get($palette,'secondary');
        border-radius: 50%;
        width: 2.7777rem;
        height: 2.7777rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.25s;
        @include xxl(){
            width: 2.083rem;
            height: 2.083rem;
            margin-right: 0.625rem;
        }
    }
    &__item .icon{
        width: 100%;
        height: 100%;
        transition: inherit;
    }
    &__item:hover{
        background-color:map-get($palette,'secondary');
    }
    &__item:hover .icon{
        fill: map-get($palette,'primary');
    }

    &__item:active{
        transform: scale(0.7);
    }
}

