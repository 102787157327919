.flexmasonry {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
}

.flexmasonry-item { width: 100%; }
.flexmasonry-cols-2 .flexmasonry-item { width: 50%; }
.flexmasonry-cols-3 .flexmasonry-item { width: 33.333%; }
.flexmasonry-cols-4 .flexmasonry-item { width: 25%; }
.flexmasonry-cols-5 .flexmasonry-item { width: 20%; }
.flexmasonry-cols-6 .flexmasonry-item { width: 16.666%; }
.flexmasonry-cols-7 .flexmasonry-item { width: 14.285%; }
.flexmasonry-cols-8 .flexmasonry-item { width: 12.5%; }

.flexmasonry-cols-2 .flexmasonry-item:nth-child(2n+1) { order: 1; }
.flexmasonry-cols-2 .flexmasonry-item:nth-child(2n)   { order: 2; }

.flexmasonry-cols-3 .flexmasonry-item:nth-child(3n+1) { order: 1; }
.flexmasonry-cols-3 .flexmasonry-item:nth-child(3n+2) { order: 2; }
.flexmasonry-cols-3 .flexmasonry-item:nth-child(3n)   { order: 3; }

.flexmasonry-cols-4 .flexmasonry-item:nth-child(4n+1) { order: 1; }
.flexmasonry-cols-4 .flexmasonry-item:nth-child(4n+2) { order: 2; }
.flexmasonry-cols-4 .flexmasonry-item:nth-child(4n+3) { order: 3; }
.flexmasonry-cols-4 .flexmasonry-item:nth-child(4n)   { order: 4; }

.flexmasonry-cols-5 .flexmasonry-item:nth-child(5n+1) { order: 1; }
.flexmasonry-cols-5 .flexmasonry-item:nth-child(5n+2) { order: 2; }
.flexmasonry-cols-5 .flexmasonry-item:nth-child(5n+3) { order: 3; }
.flexmasonry-cols-5 .flexmasonry-item:nth-child(5n+4) { order: 4; }
.flexmasonry-cols-5 .flexmasonry-item:nth-child(5n)   { order: 5; }

.flexmasonry-cols-6 .flexmasonry-item:nth-child(6n+1) { order: 1; }
.flexmasonry-cols-6 .flexmasonry-item:nth-child(6n+2) { order: 2; }
.flexmasonry-cols-6 .flexmasonry-item:nth-child(6n+3) { order: 3; }
.flexmasonry-cols-6 .flexmasonry-item:nth-child(6n+4) { order: 4; }
.flexmasonry-cols-6 .flexmasonry-item:nth-child(6n+5) { order: 5; }
.flexmasonry-cols-6 .flexmasonry-item:nth-child(6n)   { order: 6; }

.flexmasonry-cols-7 .flexmasonry-item:nth-child(7n+1) { order: 1; }
.flexmasonry-cols-7 .flexmasonry-item:nth-child(7n+2) { order: 2; }
.flexmasonry-cols-7 .flexmasonry-item:nth-child(7n+3) { order: 3; }
.flexmasonry-cols-7 .flexmasonry-item:nth-child(7n+4) { order: 4; }
.flexmasonry-cols-7 .flexmasonry-item:nth-child(7n+5) { order: 5; }
.flexmasonry-cols-7 .flexmasonry-item:nth-child(7n+6) { order: 6; }
.flexmasonry-cols-7 .flexmasonry-item:nth-child(7n)   { order: 7; }

.flexmasonry-cols-8 .flexmasonry-item:nth-child(8n+1) { order: 1; }
.flexmasonry-cols-8 .flexmasonry-item:nth-child(8n+2) { order: 2; }
.flexmasonry-cols-8 .flexmasonry-item:nth-child(8n+3) { order: 3; }
.flexmasonry-cols-8 .flexmasonry-item:nth-child(8n+4) { order: 4; }
.flexmasonry-cols-8 .flexmasonry-item:nth-child(8n+5) { order: 5; }
.flexmasonry-cols-8 .flexmasonry-item:nth-child(8n+6) { order: 6; }
.flexmasonry-cols-8 .flexmasonry-item:nth-child(8n+7) { order: 7; }
.flexmasonry-cols-8 .flexmasonry-item:nth-child(8n)   { order: 8; }

.flexmasonry-break {
    content: "";
    flex-basis: 100%;
    width: 0 !important;
    margin: 0;
}
.flexmasonry-break-1 { order: 1; }
.flexmasonry-break-2 { order: 2; }
.flexmasonry-break-3 { order: 3; }
.flexmasonry-break-4 { order: 4; }
.flexmasonry-break-5 { order: 5; }
.flexmasonry-break-6 { order: 6; }
.flexmasonry-break-7 { order: 7; }
