img{
    display: block;
    max-width: 100%;
    width: auto;
    height: auto;
}

img.lazy-load {
    display: inline-block;
    opacity: 0;
    transition: .3s
}

img.lazy-load.loaded {
    opacity: 1
}
