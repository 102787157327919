.icon {
    &:not([fill]):not([focusable]) {
        fill: currentColor;
    }

    &--nav-arrow {
        stroke: currentColor;
        fill: transparent;
        height: calc(1em * 1.4444);
    }
}

