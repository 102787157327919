@include font('Montserrat','montserrat/montserrat-v15-latin_cyrillic-ext-regular');
@include font('NeutralFace','neutralface/NeutralFaceRegular');
body {
	font-family: 'Montserrat',sans-serif;
	scroll-behavior: smooth; }

body:not(.fonts-loaded) .wrapper > * {
	opacity: 0;
	visibility: hidden; }

body.fonts-loaded .wrapper > * {
	transition: opacity 0.3s;
	opacity: 1;
	visibility: visible; }
