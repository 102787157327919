html{
    font-size: 18px;
    color: #222;
    line-height: 1.4444;
    @include xxl{
        font-size: 24px;
    }
    @include rmax(375){
        font-size: 4.8vw;
    }
    @include r(2000){
        font-size: 28px;
    }
    @include r(2300){
        font-size: 30px;
    }
    @include r(2600){
        font-size: 32px;
    }
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5{
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
}
h1,.h1,
h2,.h2
{
    font-family: 'NeutralFace',sans-serif;
}
h1,.h1{
    text-transform: uppercase;
}

h2,.h2{
    font-size: 2.2222rem;
    line-height: 1.25;
    @include md(){
        font-size: 3.33333rem;
    }
}

h3,.h3{
    font-size: 1.6666rem;
    line-height: 1.06;

    @include xxl(){
        font-size: 4.44444rem;
    }
}
p {
    margin-bottom: 20px;
}
p:last-child{
    margin-bottom: 0;
}
